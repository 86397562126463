<template>
    <div class="widget-settings">
        <a
            class="btn btn-primary-plain"
            @click="show = !show"
        >
            <i class="fas fa-md fa-cog primary-nav-icon" />
        </a>
        <el-dialog
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            :visible.sync="show"
            :show-close="false"
            title="Dashboard Widget Settings"
            width="25%"  
        >
            <div>  
                <p class="font-weight-bold">
                    Show widgets
                </p>
                <ul
                    v-if="form"
                    class="list-unstyled"
                >                  
                    <li
                        v-for="(widget, key) in form"
                        :key="key"
                    >
                        <el-checkbox
                            v-model="widget.value"
                            :label="widget.label"
                            size="large"
                        />   
                        <div
                            v-if="errors.has(`${key}.value`)"
                            class="text-danger error"
                        >
                            {{ errors.get(`${key}.value`) }}
                        </div>                     
                    </li>
                </ul>         
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <button
                        :disabled="disableSave"
                        class="btn btn-success"
                        @click="onSubmit"
                    >
                        Save
                    </button>
                </div>              
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { http, notify, routes } from "Figured/Assets/Modules";
import { Errors } from "../Errors";

export default {
    name: "WidgetSettings",

    props: {
        settings: {
            required: true
        }
    },

    data() {
        return {    
            show: false,
            errors: new Errors,
            form: Object.assign({}, JSON.parse(this.settings))
        }
    },

    computed: {
        disableSave() {
            return Object.keys(this.form).every((widget) => ! this.form[widget]["value"]);
        }
    },    

    methods: {
        onSubmit() {           
            http.post(routes.get("api.widget.settings"), this.form)
                .then(response => {
                    if (response.data.success) {
                        notify.success(response.data.message);
                        setTimeout(() => {
                            this.show = false;
                            window.location.reload();
                        }, 500);
                        
                    }              
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors.record(error.response.data);
                    } else if (error.response.status === 400) {
                        notify.error(error.response.data.message);
                    } else {
                        notify.error("Something has gone wrong. Please contact Figured Support.")
                    }
                });
        },       
    },
}
</script>

<style lang="scss">
    .widget-settings {
        .el-dialog {
            min-width: 300px;
        }

        .error {
            font-size: 12px;
        }
    }
</style>