<template>
    <div
        v-if="isWidgetAvailable"
        class="dashboard-widget"
        :class="widgetTypeClass"
    >
        <div class="widget-header">
            <div class="icon">
                <i :class="widgetData.icon" />
            </div>
            <div class="title">
                {{ widgetData.title }}
            </div>
            <div
                v-if="dismissRoute"
                class="dismiss"
                @click="dismissWidget"
            >
                <i class="el-icon-close" />
            </div>
        </div>
        <div class="widget-content">
            {{ widgetData.content }}
        </div>
        <div
            v-if="widgetData.actionUrl"
            class="widget-action"
        >
            <el-button
                plain
                size="small"
                type="primary"
                @click="doAction"
            >
                {{ widgetData.actionContent }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { http, routes } from "Figured/Assets/Modules";

export default {
    name: "DashboardWidget",

    props: {
        dataRoute: {
            required: true,
            type: String,
        },
        options: {
            required: false,
            type: Object,
            default: null,
        },
        dismissRoute: {
            required: false,
            type: String,
            default: null,
        },
    },

    data () {
        return {
            loading: true,
            widgetData: null,
        }
    },

    computed: {
        isWidgetAvailable() {
            return !this.loading && !!this.widgetData;
        },
        widgetTypeClass() {
            return this.widgetData.type || null;
        },
    },

    methods: {
        fetchWidgetData() {
            this.loading = true;

            const route = routes.get(this.dataRoute);

            http.get(route, {
                params: this.options
            })
                .then((response) => {
                    this.widgetData = response.data;
                }).catch((error) => {
                    this.widgetData = null;
                }).finally(() => {
                    this.loading = false;
                });
        },

        dismissWidget() {
            const route = routes.get(this.dismissRoute);

            http.post(route)
                .finally(() => {
                    this.widgetData = null;
                    this.loading = false;
                });
        },

        doAction() {
            if (!this.isWidgetAvailable || !this.widgetData.actionUrl) {
                return;
            }

            window.location.href = this.widgetData.actionUrl;
        },
    },

    mounted() {
        this.fetchWidgetData();
    }
}
</script>

<style scoped lang="scss">
@import 'src/Figured/Packages/Features/Lending/Assets/lending-colours.scss';
$icon-column: 30px;
.dashboard-widget {
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;

    // @TODO add another colours/types sometimes later
    &.lending {
        background-color: $lending-orange-background;
        color: $lending-orange-text;
    }

    .widget-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 7px;

        .icon {
            width: $icon-column;
        }
        .title {
            font-weight: bold;
            flex-grow: 1;
        }
        .dismiss {
            text-align: right;
            cursor: pointer;
            margin-top: -3px;   // move dismiss button ("X") to top
        }
    }

    .widget-content {
        padding-left: $icon-column;
        padding-right: 15px;
    }

    .widget-action {
        padding-top: 15px;
        text-align: right;
    }
}
</style>
