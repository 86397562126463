<template>
    <div class="margin-bottom-md border border-solid border-fg-muted-grey p-2.5">
        <div class="justify-space-between flex">
            <h5>KPIs</h5>
            <div class="flex place-content-between gap-8">
                <DashboardKpisSettings
                    v-if="!hasFailed"
                    @loadedSettings="onSettingsLoaded"
                    @savedSettings="onSettingsSaved"
                />
                <el-radio-group
                    v-model="basis"
                    size="small"
                    class="setting-input"
                    @change="fetchKpis"
                >
                    <el-radio-button label="cash">
                        {{ translateTerminology('cash') }}
                    </el-radio-button>
                    <el-radio-button label="accrual">
                        {{ translateTerminology('accrual') }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div
            v-if="!hasFailed"
            v-loading="loading"
            class="report-kpi flex flex-wrap"
        >
            <div v-if="isAllKpisDisabled">
                <p>
                    All KPIs are disabled. Please use the settings cog to enable a KPI.
                </p>
            </div>
            <div
                v-for="(kpi, key) in kpiData"
                class="kpi-box relative"
            >
                <el-tooltip
                    v-if="isLendingBadge(key)"
                    effect="dark"
                    :content="lendingBadgeContent(key)"
                    placement="top"
                >
                    <img
                        class="kpi-lending pointer relative -right-2.5 -top-2.5 float-right size-9"
                        src="/img/lending/lending-icon-orange.png"
                        alt="Lending"
                        @click="goToLending()"
                    >
                </el-tooltip>
                <el-popover
                    placement="bottom"
                    trigger="hover"
                >
                    <!--       KPI Tooltip         -->
                    <div class="z-10 flex min-w-max flex-col gap-y-2.5 bg-fg-white p-3">
                        <div class="mb-3 font-bold">
                            {{kpi['title']}}
                        </div>
                        <div class="gap-y-4 bg-fg-super-pale-grey p-5 font-semibold text-black">
                            {{kpi['formula']}}
                            <div
                                v-for="extraInfo in kpi['extraInfo']"
                                class="text-md mt-2.5 font-normal text-fg-mid-grey"
                            >
                                {{ extraInfo }}
                            </div>
                        </div>
                        <div class="flex flex-col-reverse">
                            <div
                                v-for="(interval, hash) in intervals"
                                class="mt-3.5 flex flex-row justify-between font-bold text-fg-mid-grey"
                            >
                                {{interval['label']}}
                                <span class="ml-24 text-black">{{kpi['data'][hash]['operands'] + " = " + kpi['data'][hash]['value']}}</span>
                            </div>
                        </div>
                    </div>

                    <div
                        slot="reference"
                        class="kpi-inner-box"
                    >
                        <div class="kpi-label padding-bottom-sm">
                            {{kpi['title']}}
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div
                                v-for="(row, intervalHash) in kpi.data"
                                class="pr-2"
                            >
                                <div class="kpi-value">
                                    {{ row['value'] }}
                                    <span
                                        v-if="row['performance_indicator']"
                                        class="kpi-performance-indicator"
                                        :class="row['performance_indicator']['class']"
                                    >
                                        <i :class="row['performance_indicator']['arrow_icon']" />
                                        {{ row['performance_indicator']['formatted_value'] }}
                                    </span>
                                </div>
                                <div class="kpi-period-label">
                                    {{intervals[intervalHash]['label']}}
                                </div>
                                <div class="kpi-period-label">
                                    {{ intervals[intervalHash]['type_label'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>

        <div
            v-else
            class="kpi-failed-text"
        >
            Could not fetch farm kpis
        </div>
    </div>
</template>

<script>
import {http, lang, routes} from "Figured/Assets/Modules";
import {find, has} from "lodash";
import DashboardKpisSettings from "DashboardCharts/Assets/Components/DashboardKpisSettings.vue";

export default {
    name: "DashboardKpis",
    components: {DashboardKpisSettings},

    data() {
        return {
            kpiData: null,
            intervals: null,
            basis: "accrual",
            loading: false,
            hasFailed: false,
            lending: null,
            kpiSettings: null,
        }
    },

    computed: {
        isAllKpisDisabled() {
            if (this.kpiSettings === null) return false
            let isAllDisabled = true;
            for (let kpi in this.kpiSettings) {
                // If any kpi is enabled, then return false immediately as not all are disabled
                if (this.kpiSettings[kpi].isEnabled) {
                    return false;
                }
            }
            // If we haven't already returned, then all kpis must be disabled
            return isAllDisabled;
        },
    },

    methods: {
        translateTerminology(key) {
            return lang.trans("terminology." + key);
        },

        fetchKpis() {
            this.hasFailed = false;
            this.loading = true;

            const year      = window.Figured.GlobalDateSelector.getYear();
            const type      = window.Figured.GlobalDateSelector.getBudgetType();
            const actualsTo = window.Figured.GlobalDateSelector.getHorizon()

            let route = routes.get("service.reports.dashboard.kpis")

            http.get(route, {
                params: {
                    year: year,
                    type: type,
                    basis: this.basis,
                    actuals_to: actualsTo
                }
            }).then((response) => {
                let data = response.data.data;

                this.kpiData   = data.data;
                this.intervals = data.intervals;

                this.fetchLendingState();
            }).catch((error) => {
                this.hasFailed = true;
            }).finally(() => {
                this.loading = false;
            })
        },

        fetchLendingState() {
            const route = routes.get("api.lending.kpi");

            let kpiData = [];

            for (let indicatorName in this.kpiData) {
                let data = find(
                    this.kpiData[indicatorName].data,
                    (item) => has(item, "performance_indicator")
                );

                if (!data) {
                    continue;
                }

                kpiData.push({
                    indicator_name:  indicatorName,
                    value:           data.value,
                    indicator_value: data["performance_indicator"].value,
                });
            }

            const payload = {
                year:       window.Figured.GlobalDateSelector.getYear(),
                budgetType: window.Figured.GlobalDateSelector.getBudgetType(),
                actualsTo:  window.Figured.GlobalDateSelector.getHorizon(),
                kpiData:    kpiData,
            };

            http.post(route, payload)
                .then((response) => {
                    this.lending = response.data;
                });
        },

        goToLending() {
            window.location.href = routes.get("lending");
        },

        lendingBadgeContent(indicatorName) {
            if (!this.lending) {
                return null;
            }

            const contentKey = this.lending[indicatorName];

            return contentKey ? lang.trans(contentKey) : null;
        },

        isLendingBadge(indicatorName) {
            if (!this.lending) {
                return false;
            }

            return !!this.lendingBadgeContent(indicatorName);
        },

        onSettingsLoaded(settings) {
            this.kpiSettings = settings;
        },

        onSettingsSaved(settings) {
            this.kpiSettings = settings;
            this.fetchKpis();
        }
    },

    mounted() {
        this.loading = true;

        // Let Window.Figured.GlobalDateSelector initialize. Dashboard initialization in app.js, GDS in globalDateSelector.js
        // See views/layouts/master.blade.php
        setTimeout(() => {
            this.fetchKpis();
        }, 1000)
    },
}
</script>

<style scoped  lang="scss">
@import 'src/Figured/Assets/Themes/default/_report-kpis.scss';
</style>
