<template>
    <dashboard-widget
        data-route="api.lending.widget"
        :options="options"
        dismiss-route="api.lending.widget.dismiss"
    />
</template>

<script>
import DashboardWidget from "Lending/Assets/Components/Widgets/DashboardWidget";

export default {
    name: "LendingNotificationWidget",
    components: {
        DashboardWidget
    },
    props: {
        year: {
            type: Number|String,
            required: true,
        },
        actualsToHorizon: {
            type: String,
            required: true,
        },
    },
    computed: {
        options() {
            return {
                year:      this.year,
                actualsTo: this.actualsToHorizon,
            }
        }
    },
}
</script>
