<template>
    <div
        v-if="displayAudit"
        class="panel panel-default panel-page"
    >
        <div class="panel-heading">
            Activity
        </div>
        <div class="divide-y">
            <AuditMessage
                v-for="(audit) in audits"
                :key="auditKey(audit)"
                :audit="audit"
            />
        </div>
        <div
            v-if="auditSettingsRoute"
            class="panel-footer"
        >
            <a
                :href="auditSettingsRoute"
                class="pull-right btn btn-default"
            >View all activity</a>

            <div class="clearfix" />
        </div>
    </div>
    <span v-else />
</template>

<script>
import {routes, http} from "Figured/Assets/Modules";
import AuditMessage from "Audit/Assets/Components/Elements/AuditMessage";
import {isEmpty} from "lodash";

export default {
    name: "DashboardAuditWidget",
    components: {
        AuditMessage
    },

    props: {
        isFarmAdviser: {
            required: true,
            type: Boolean,
        },
    },

    data() {
        return {
            audits: [],
            auditSettingsRoute: routes.get("settings.audit"),
            auditMessagesRoute: routes.get("dashboard.audit.messages"),
        };
    },

    computed: {
        displayAudit() {
            return !isEmpty(this.audits);
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            http.get(this.auditMessagesRoute)
                .then((response) => {
                    this.audits = response.data;
                });
        },

        auditKey(audit) {
            return "audit-" + audit.id;
        },
    },
}
</script>
