<template>
    <div class="panel panel-default panel-page">
        <div class="panel-heading">
            <span class="chart-title">
                Operation Trackers
            </span>
            <img
                v-if="loadingReconciliation"
                class="pull-right small-spinner inline"
                title="Loading reconciliation status"
                alt=""
                src="/img/loading.gif"
            >
        </div>
        <table class="table-hover table">
            <tbody>
                <tr
                    v-for="tracker in trackers"
                    :key="tracker.id"
                >
                    <td>
                        <a :href="tracker.url">{{ tracker.name }}</a>
                        <span
                            v-if="!loadingReconciliation && trackerNeedsReconciliation(tracker)"
                            :title="getWarning(tracker)"
                            class="pull-right"
                            data-toggle="tooltip"
                            data-placement="left"
                        >
                            <a :href="getReconciliationLink(tracker)">
                                <i class="fa-solid fa-exclamation-triangle text-warning" />
                            </a>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {routes, http} from "Figured/Assets/Modules";
import {activeFarmIsDemo} from "Assets/Modules/globals";

export default {
    name: "operation-trackers",
    props: {
        trackers: {
            type: Array,
            required: true,
        }
    },
    data: function() {
        return {
            loadingReconciliation: true,
            unreconciledTrackers: [],
        }
    },
    methods: {
        async loadUnreconciledTrackerList() {
            const route = routes.get("dashboard.unreconciledOperationTrackers");
            const response = await http.get(route);

            this.unreconciledTrackers = response.data;
            this.loadingReconciliation = false;
        },
        trackerNeedsReconciliation(tracker) {
            if (!this.isIntegratedTracker && !activeFarmIsDemo()) {
                return this.getUnreconciledTracker(tracker) !== false;
            }
        },
        getUnreconciledTracker(tracker) {
            const index = this.unreconciledTrackers
                .findIndex(unreconciledTracker => unreconciledTracker.url === tracker.url && unreconciledTracker.canTrackerImportFromAccountingSystem);
            if (index < 0) {
                return false;
            }

            return this.unreconciledTrackers[index];
        },
        getWarning(tracker) {
            return this.getUnreconciledTracker(tracker).warning;
        },
        getReconciliationLink(tracker) {
            return this.getUnreconciledTracker(tracker).reconcileUrl;
        },
    },
    mounted() {
        this.loadUnreconciledTrackerList()
    },
}
</script>

<style scoped>

  img.small-spinner {
    width: 30px;
    height: 30px;
  }

</style>
