<template>
    <div class="px-8 py-3 hover:bg-fg-dark-blue-6">
        <div class="font-bold">
            {{ dateDiff }}
        </div>
        <div>{{ message }}</div>
    </div>
</template>
<script>

export default {
    name: "AuditMessage",

    props: {
        audit: {
            required: true,
            type: Object,
        },
    },

    computed: {
        dateDiff() {
            return this.audit.diff;
        },
        message() {
            return this.audit.message || "No message";
        },
    },
}
</script>
