<template>
    <div class="kpi-settings">
        <a
            class="btn btn-primary-plain"
            @click="show = !show"
        >
            <i class="fas fa-md fa-cog primary-nav-icon" />
        </a>
        <el-dialog
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            :visible.sync="show"
            :show-close="true"
            title="KPI Settings"
            width="25%"
        >
            <div>
                <p class="font-bold">
                    Show KPIs
                </p>
                <ul v-if="form && !hasFailed">
                    <li
                        v-for="(kpi, key) in form"
                        :key="key"
                    >
                        <el-checkbox
                            v-model="kpi.isEnabled"
                            :label="kpi.label"
                            size="large"
                        />
                    </li>
                </ul>
                <span
                    v-if="!form && !hasFailed"
                    v-loading="!form && !hasFailed"
                    class="grid size-full place-content-center"
                />
                <p v-if="hasFailed">
                    An error occurred while trying to fetch the KPI settings. Please refresh the page.
                </p>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <button
                        :disabled="isSaveDisabled"
                        class="btn btn-success"
                        @click="onSubmit"
                    >
                        Save
                    </button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import {http, notify, routes} from "Assets/Modules";

export default {
    name: "DashboardKpisSettings",

    data() {
        return {
            show: false,
            form: null,
            hasFailed: false,
        }
    },
    computed: {
        isSaveDisabled() {
            return (this.form === null || this.hasFailed)
        }
    },

    methods: {
        onSubmit() {
            let kpis = [];
            for (let kpi in this.form) {
                kpis.push({kpi: kpi, isEnabled: this.form[kpi].isEnabled});
            }

            let postData = {kpis};

            http.post(routes.get("api.kpi.settings"), postData)
                .then(response => {
                    if (response.data.success) {
                        notify.success(response.data.message);
                        this.show = false;
                        this.$emit("savedSettings", _.cloneDeep(this.form));

                    }
                })
                .catch(error => {
                    if (error.response.status === 422 || error.response.status === 400) {
                        notify.error(error.response.data.message);
                    } else {
                        notify.error("Something has gone wrong. Please contact Figured Support.")
                    }
                });
        },
        fetchKpiSettings() {
            const route = routes.get("service.reports.dashboard.kpi_settings");

            http.get(route)
                .then((response) => {
                    this.form = response.data;
                    this.$emit("loadedSettings", _.cloneDeep(this.form));
                })
                .catch(() => {
                    this.hasFailed = true;
                    notify.error("Failed to fetch KPI settings");
                });
        }
    },
    mounted() {
        this.fetchKpiSettings();
    },
}
</script>